<template>
  <div>
    <CustomMultiselect
      :value="value"
      track-by="label"
      label="label"
      :options="options"
      :placeholder="placeholder"
      :title="placeholder"
      :multiple="false"
      :show-labels="false"
      @input="$emit('input', $event || null)"
    >
    </CustomMultiselect>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
export default {
  components: {
    CustomMultiselect,
  },
  props: {
    placeholder: { type: String },
    value: { type: Object },
  },
  data() {
    return {
      options: [
        {
          label: this.$t('COLUMNNAMES.ReiseterminBlankData_Garantiert'),
          filter: { ['garantiert']: { eq: true } },
        },
        {
          label: this.$t('COMMON.NICHTGARANTIERT'),
          filter: { ['garantiert']: { eq: false } },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.multiselect--disabled {
  background: none;
}
</style>
