<template>
  <div>
    <Multiselect
      :value="value"
      track-by="label"
      label="label"
      :options="options"
      :placeholder="placeholder"
      :title="placeholder"
      :multiple="true"
      :show-labels="false"
      @input="$emit('input', $event)"
    >
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect,
  },
  props: {
    placeholder: { type: String },
    value: { type: Array },
  },
  data() {
    return {
      options: [
        {
          label: this.$t('FILTER.DATE.UNTIL8DAYS'),
          filter: { ['metadata/reisedauer']: { le: 8 } },
        },
        {
          label: this.$t('FILTER.DATE.8TIL15DAYS'),
          filter: { and: [{ ['metadata/reisedauer']: { ge: 8 } }, { ['metadata/reisedauer']: { le: 15 } }] },
        },
        {
          label: this.$t('FILTER.DATE.ABOVE15DAYS'),
          filter: { ['metadata/reisedauer']: { gt: 15 } },
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';
.multiselect--disabled {
  background: none;
}
</style>
